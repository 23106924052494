import config from '../config/index';
import queryString from 'query-string';
import { Business } from '../models/User.model';
import _ from 'lodash';
import { Product } from '../models/CartWrapper';

export class FilteredProductField {
  public productField = '';
  public values: string[] = [];
}

function filterProductFields(
  business: Business,
  productField: string,
  filterJson: { [key: string]: string[] }
): Promise<FilteredProductField> {
  const qs = queryString.stringify({
    filterJson: JSON.stringify(filterJson)
  });
  if (business && business.id) {
    return fetch(
      `${config.ruleServerUrl}/users/casa-product-master/tenantBrandBusiness/${business.id}/field/${productField}?${qs}`,
      {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
      }
    ).then(r => r.json());
  } else {
    return Promise.reject('business unit missing');
  }
}

// todo: should pass businessId - Ragavan
// todo: introduce pagination - Ragavan
function filterOfferProducts(
  offerCode: string,
  bucketId: number
): Promise<Product[]> {
  return fetch(
    `${config.ruleServerUrl}/users/casa-product-master/offer/${offerCode}/bucket/${bucketId}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  )
    .then(r => r.json())
    .then(ps => _.map(ps, p => _.extend(new Product(), p)));
}

function listProducts(business: Business): Promise<Product[]> {
  const qs = queryString.stringify(
    {
      isActive: true,
      isRemoveMasterData: true
    },
    { arrayFormat: 'bracket' }
  );
  if (business && business.id) {
    return fetch(
      `${config.ruleServerUrl}/users/casa-product-master/list/${business.id}?${qs}`,
      {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
      }
    )
      .then(r => r.json())
      .then(response => response.data)
      .then(ps => _.map(ps, p => _.extend(new Product(), p)));
  } else {
    return Promise.reject('business unit missing');
  }
}

function listProductsByToken(token: string): Promise<Product[]> {
  const qs = queryString.stringify(
    {
      token: token,
      isActive: true,
      isRemoveMasterData: true
    },
    { arrayFormat: 'bracket' }
  );
  if (token) {
    return fetch(
      `${config.ruleServerUrl}/casa-product-master/list/token?${qs}`,
      {
        credentials: 'include',
        method: 'GET',
        headers: {
          'Content-type': 'application/json'
        }
      }
    )
      .then(r => r.json())
      .then(response => response.data)
      .then(ps => _.map(ps, p => _.extend(new Product(), p)));
  } else {
    return Promise.reject('business unit missing');
  }
}

function bulkUploadProductMaster(data: any, businessUnitId: number) {
  return fetch(
    `${config.ruleServerUrl}/users/casa-product-master/bulk/${businessUnitId}`,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(data)
    }
  ).then(r => r.json());
}
function listProductsBySku(skus: any): Promise<Product[]> {
  return fetch(
    `${config.ruleServerUrl}/users/casa-product-master/flipbook-products`,
    {
      credentials: 'include',
      method: 'POST',
      headers: {
        'Content-type': 'application/json'
      },
      body: JSON.stringify(skus)
    }
  )
    .then(r => r.json())
    .then(response => response.data)
    .then(ps => _.map(ps, p => _.extend(new Product(), p)));
}

function listProductsBySkuAndToken(
  token: string,
  skus: any
): Promise<Product[]> {
  const qs = queryString.stringify(
    {
      token: token
    },
    { arrayFormat: 'bracket' }
  );
  if (token) {
    return fetch(
      `${config.ruleServerUrl}/casa-product-master/flipbook-products/token?${qs}`,
      {
        credentials: 'include',
        method: 'POST',
        headers: {
          'Content-type': 'application/json'
        },
        body: JSON.stringify(skus)
      }
    )
      .then(r => r.json())
      .then(response => response.data)
      .then(ps => _.map(ps, p => _.extend(new Product(), p)));
  } else {
    return Promise.reject('business unit missing');
  }
}

export default {
  filterProductFields,
  filterOfferProducts,
  listProducts,
  listProductsByToken,
  bulkUploadProductMaster,
  listProductsBySku,
  listProductsBySkuAndToken
};
