import { FlipbookPage } from './common';
import React, { FC, useContext, useEffect, useState } from 'react';
import FlipbookPageDimensions from '../Utiles/FlipbookPageDimensions';
import FlipbookPageGradient from '../Utiles/FlipbookPageGradient';
import MapContainer from '../Maps/Maps';
import { Card, IconButton } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import _, { isEmpty } from 'lodash';
import WhatsappChatIcon from '../../Assets/images/whatsapp_chat_icon.svg';
import CallIcon from '../../Assets/images/call_icon.png';
import MapDirectionsIcon from '../../Assets/images/map_directions_icon.svg';
import MapCloseDirectionsIcon from '../../Assets/images/map_close_directions_icon.svg';
import { TenantStore } from '../../../models/User.model';
import {
  getCartItemsAsText,
  getValidMobileNumber
} from '../Utiles/FlipbookUtiles';
import casaProductMaster from '../../../services/productMaster.service';
import { FlipbookCartContext } from '../Hooks/FlipbookCartContext';

interface FlipbookBackPageProps {
  pageNumber: number;
  storeDetails: TenantStore[];
  businessUnitDetails: any;
  currentStore: any;
  flipbookIdOrToken: any;
}

export const FlipbookBackPage: FC<FlipbookBackPageProps> = ({
  pageNumber,
  storeDetails,
  businessUnitDetails,
  currentStore,
  flipbookIdOrToken
}) => {
  const [
    storeDetailsForView,
    setStoreDetailsForView
  ] = React.useState<{} | null>();
  const [isDirectionMapEnabled, setIsDirectionMapEnabled] = React.useState(
    false
  );
  const [storeDetailsByDistance, setStoreDetailsByDistance] = React.useState<
    TenantStore[]
  >([]);
  const [currentGeolocation, setCurrentGeolocation] = React.useState({
    currentLatitude: 0,
    currentLongitude: 0
  });
  const [userSelectedStore, setUserSelectedStore] = React.useState<{}>();
  const [allProducts, setAllProducts] = useState<any>();
  const flipbookCartContext = useContext(FlipbookCartContext);
  const cartItems: any = flipbookCartContext.cartItems;

  useEffect(() => {
    const filteredSkus = _.map(cartItems, 'sku');
    if (!_.isEmpty(filteredSkus)) {
      casaProductMaster
        .listProductsBySkuAndToken(flipbookIdOrToken, filteredSkus)
        .then(products => {
          setAllProducts(products);
        });
    }
  }, [cartItems, flipbookIdOrToken]);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition(
      function(position) {
        const { latitude, longitude } = position.coords;
        setCurrentGeolocation({
          currentLatitude: latitude,
          currentLongitude: longitude
        });
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {},
      { enableHighAccuracy: true }
    );
  }, []);

  useEffect(() => {
    const { currentLatitude, currentLongitude } = currentGeolocation;
    const storeDetailsByDistance = _(storeDetails)
      .map(store => {
        const distance = getDistanceUsingLatLong(
          currentLatitude,
          currentLongitude,
          store.latitude,
          store.longitude,
          'K'
        );
        return _.extend(store, { distance: distance });
      })
      .orderBy(['distance'], ['asc'])
      .map((v, i) => {
        return _.extend(v, { order: i });
      })
      .value();
    setStoreDetailsForView(_.find(storeDetailsByDistance, { order: 0 }));
    setStoreDetailsByDistance(storeDetailsByDistance);
  }, [currentGeolocation, storeDetails]);

  function getDistanceUsingLatLong(
    lat1: number,
    lon1: number,
    lat2: number,
    lon2: number,
    unit: string
  ) {
    // Unit:
    // 'M' is statute miles (default)
    // 'K' is kilometers
    // 'N' is nautical miles

    if (lat1 === lat2 && lon1 === lon2) {
      return 0;
    } else {
      const radlat1 = (Math.PI * lat1) / 180;
      const radlat2 = (Math.PI * lat2) / 180;
      const theta = lon1 - lon2;
      const radtheta = (Math.PI * theta) / 180;
      let dist =
        Math.sin(radlat1) * Math.sin(radlat2) +
        Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
      if (dist > 1) {
        dist = 1;
      }
      dist = Math.acos(dist);
      dist = (dist * 180) / Math.PI;
      dist = dist * 60 * 1.1515;
      if (unit === 'K') {
        dist = dist * 1.609344;
      }
      if (unit === 'N') {
        dist = dist * 0.8684;
      }
      return dist;
    }
  }

  function storeDetailsCardComponent() {
    return (
      <div className={'store-details-card-section'}>
        <div>
          <h3 className={'store-details-card-header'}>
            {_.get(storeDetailsForView, 'name', '')}
          </h3>
        </div>
        <div>
          <p className={'store-details-card-address'}>
            {_.get(storeDetailsForView, 'addressLineOne', '')}
          </p>
          <p className={'store-details-card-address'}>
            {_.get(storeDetailsForView, 'addressLineTwo', '')}
          </p>
          <p className={'store-details-card-address'}>
            {_.get(storeDetailsForView, 'city', '')} -{' '}
            <span>{_.get(storeDetailsForView, 'pincode', '')}</span>
          </p>
        </div>
      </div>
    );
  }

  const handleStoreDetailsCardChange = (type: string) => {
    const currentCardOrder = _.get(storeDetailsForView, 'order');
    let updatedCurrentStore: any;
    const maxOrderNum = _.get(
      _.maxBy(storeDetailsByDistance, 'order'),
      'order'
    );
    const minOrderNum = _.get(
      _.minBy(storeDetailsByDistance, 'order'),
      'order'
    );
    if (type === 'for') {
      updatedCurrentStore = _.find(storeDetailsByDistance, {
        order:
          maxOrderNum === currentCardOrder ? minOrderNum : currentCardOrder + 1
      });
    } else if (type === 'back') {
      updatedCurrentStore = _.find(storeDetailsByDistance, {
        order:
          minOrderNum === currentCardOrder ? maxOrderNum : currentCardOrder - 1
      });
    }
    setStoreDetailsForView(updatedCurrentStore);
  };

  const onClickRouteIcon = () => {
    setIsDirectionMapEnabled(!isDirectionMapEnabled!);
  };

  const googleMapsApiKeyDetails = _.find(
    _.get(businessUnitDetails, 'accessKeys', []),
    {
      type: 'googleMapsAPI'
    }
  );

  useEffect(() => {
    if (isEmpty(userSelectedStore) && !isEmpty(currentStore)) {
      setUserSelectedStore(currentStore);
      setStoreDetailsForView(currentStore);
    }
  }, [currentStore, userSelectedStore]);

  const validMobileNumber = getValidMobileNumber(storeDetailsForView);
  const whatsAppText = getCartItemsAsText(cartItems, allProducts, 'mapPage');

  return (
    <FlipbookPage
      width={FlipbookPageDimensions.getFlipbookWidth()}
      height={FlipbookPageDimensions.getFlipbookHeight()}
    >
      {
        <div className={'height-100-per'}>
          <div className={'height-100-per'}>
            <div className={'store-location-map-container'}>
              <MapContainer
                currentGeolocation={currentGeolocation}
                storeGeolocation={{
                  storeLatitude: _.get(storeDetailsForView, 'latitude'),
                  storeLongitude: _.get(storeDetailsForView, 'longitude')
                }}
                isDirectionMapEnabled={isDirectionMapEnabled}
                googleMapsApiKey={_.get(googleMapsApiKeyDetails, 'key', '')}
              />
            </div>
            <div>
              <div
                className={'store-pagination'}
                style={{ textAlign: 'center' }}
              >
                <span>
                  <strong>{_.get(storeDetailsForView, 'order') + 1}</strong>{' '}
                  <div style={{ color: 'grey', display: 'inline' }}>
                    {' '}
                    of {storeDetailsByDistance.length}
                  </div>
                </span>
              </div>
              <div className={'store-address-list-container'}>
                <IconButton
                  aria-label="ArrowBack"
                  className={'outline-none'}
                  onClick={() => handleStoreDetailsCardChange('back')}
                  style={{ color: '#F2755F' }}
                >
                  <ArrowBackIosIcon />
                </IconButton>
                <div>
                  <Card className={`address-card`} variant="outlined">
                    {storeDetailsCardComponent()}
                  </Card>
                </div>
                <IconButton
                  aria-label="ArrowForward"
                  className={'outline-none'}
                  onClick={() => handleStoreDetailsCardChange('for')}
                  style={{ color: '#F2755F' }}
                >
                  <ArrowForwardIosIcon />
                </IconButton>
              </div>
            </div>
            <div className={'store-details-bottom-container'}>
              <div className={'store-details-icon-section'}>
                <a
                  href={`http://wa.me/${validMobileNumber}?text=${whatsAppText}`}
                  title="Share on whatsapp"
                >
                  <IconButton
                    aria-label="ArrowBack"
                    className={'outline-none store-details-icon'}
                  >
                    <img
                      src={WhatsappChatIcon}
                      className={'store-details-icon-img'}
                      alt={'whats app chat'}
                    />
                  </IconButton>
                </a>
                <p className={'store-details-icon-label'}>{'Chat'}</p>
              </div>
              <div className={'store-details-icon-section'}>
                <a href={`tel:${_.get(storeDetailsForView, 'mobile', '')}`}>
                  <IconButton
                    aria-label="ArrowBack"
                    className={'outline-none store-details-icon'}
                  >
                    <img
                      src={CallIcon}
                      className={'store-details-icon-img'}
                      alt={'whats app chat'}
                    />
                  </IconButton>
                </a>
                <p className={'store-details-icon-label'}>{'Call'}</p>
              </div>
              <div className={'store-details-icon-section'}>
                {!isDirectionMapEnabled ? (
                  <div>
                    <IconButton
                      aria-label="ArrowBack"
                      className={'outline-none store-details-icon'}
                      onClick={() => onClickRouteIcon()}
                    >
                      <img
                        src={MapDirectionsIcon}
                        className={'store-details-icon-img'}
                        alt={'whats app chat'}
                      />
                    </IconButton>{' '}
                    <p className={'store-details-icon-label'}>{'Go'}</p>
                  </div>
                ) : (
                  <div>
                    <IconButton
                      aria-label="ArrowBack"
                      className={'outline-none store-details-icon'}
                      onClick={() => onClickRouteIcon()}
                    >
                      <img
                        src={MapCloseDirectionsIcon}
                        alt={'whats app chat'}
                      />
                    </IconButton>
                    <p className={'store-details-icon-label'}>{'Close'}</p>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className={'page-effect'}
            style={{
              backgroundImage: FlipbookPageGradient.getGradientForPage(
                pageNumber
              )
            }}
          ></div>
        </div>
      }
    </FlipbookPage>
  );
};

export default FlipbookBackPage;
