import _ from 'lodash';

export const USER_TOKEN = 'user';
export const CART_TOKEN = 'cart';

export const DEFAULT_FLIPBOOK_CONFIG = {
  isSplitShipment: false,
  defaultCheckout: 'store',
  availableCheckouts: ['FlipbookCheckout', 'Ecommerce', 'Store'],
  isHideOutOfStockProducts: false
};
export const AVAILABLE_CHECKOUTS = {
  FlipbookCheckout: {
    name: 'Flipbook Checkout',
    key: 'FlipbookCheckout',
    actions: 'Add to cart',
    reverseActions: 'Remove from cart',
    cartPageTitle: 'My Cart',
    cartPageMainHeader: 'Your cart is empty!',
    cartPageSubHeader: 'Add products to cart now'
  },
  Ecommerce: {
    name: 'Ecommerce',
    key: 'Ecommerce',
    actions: 'Add to cart',
    reverseActions: 'Remove from cart',
    cartPageTitle: 'My Cart',
    cartPageMainHeader: 'Your cart is empty!',
    cartPageSubHeader: 'Add products to cart now'
  },
  Store: {
    name: 'Chat & Buy from Store',
    key: 'Store',
    actions: 'Reserve Product',
    reverseActions: 'Unreserve Product',
    cartPageTitle: 'Reservation Summary',
    cartPageMainHeader: 'No products reserved!',
    cartPageSubHeader: 'Add a product now'
  }
};
export const getFlipbookConfiguration = (user: any) => {
  return _.get(
    user,
    'tenant.brands.0.businesses.0.flipbookConfig',
    DEFAULT_FLIPBOOK_CONFIG
  );
};
