import React, { FC, useState, useEffect } from 'react';
import flipbookService from '../../services/flipbook.service';

import { CircularProgress, Modal } from '@material-ui/core';
import useCurrentUser from '../../hooks/useCurrentUser';
import { Business } from '../../models/User.model';
import _, { isNull } from 'lodash';
import config from '../../config/index';
import AddCircleOutlineSharpIcon from '@material-ui/icons/AddCircleOutlineSharp';
import Snackbar from '@material-ui/core/Snackbar';
import Slide from '@material-ui/core/Slide';
import FlipbookCard from './FlipbookCard';
import './Flipbook.css';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

export const FlipbookListing: FC<{ match: any }> = ({ match }) => {
  const [flipbooks, setFlipbooks] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);
  const [business, setBusiness] = useState(new Business());
  const [isEditable, setIsEditable] = useState(false);
  // eslint-disable-next-line
  const [allowAccess, setAllowAccess] = useState(true);
  const classes = useStyles();
  const redirectToCreate = () => {
    window.location.href = `/flipbook/create`;
  };
  const redirectToView = (id: number) => {
    window.location.href = `/flipbook/view/${id}`;
  };
  const redirectToEdit = (id: number) => {
    window.location.href = `/flipbook/edit/${id}`;
  };
  const user = useCurrentUser();
  const stores = _.get(user, 'tenant_stores', []);

  useEffect(() => {
    const roleGroups = _.get(user, 'tenant_role_groups', []);
    const permissions = _.flatMap(roleGroups, 'permissions');
    setIsEditable(_.includes(permissions, 'flipbook_edit'));
  }, [user]);

  useEffect(() => {
    /*flipbookService.getFlipbookValidity('flipsell').then(result => {
      setAllowAccess(result.validity);
    });*/
  });

  useEffect(() => {
    setBusiness(
      _(user.getBusinesses())
        .orderBy(['id'])
        .first() || new Business()
    );
  }, [user, setBusiness]);

  useEffect(() => {
    const storeName = _.get(_.head(stores), 'store', '');
    flipbookService.listFlipbooks(storeName).then(flipbooks => {
      setIsLoading(false);
      setFlipbooks(_.orderBy(flipbooks, f => new Date(f.updatedAt), ['desc']));
    });
  }, [stores]);

  const getCurrentUserStoreCode = () => {
    const allStores = _.get(user, 'tenant_stores', []);
    const activeStores = _.filter(allStores, { isActive: true });
    const firstStore = _.head(activeStores);
    return _.get(firstStore, 'store', undefined);
  };
  const getStoreCodeAsQueryParams = () => {
    const storeCode = getCurrentUserStoreCode();
    return _.isEmpty(storeCode) ? '' : `?store=${storeCode}`;
  };
  const getFlipbookURL = (id: number) => {
    const businessUnitId = _.get(business, 'id');
    const tenantId = _.get(user, 'id');
    const tenantEsKey = _.get(user, 'tenant.esKey');
    flipbookService
      .getFlipbookJwtTokenById(tenantId, tenantEsKey, id, businessUnitId)
      .then(jwtToken => {
        const generatedUrlWithToken = `${
          config.casaWebAppUrl
        }/flipbook/customer-view/${_.get(
          jwtToken,
          'jwt'
        )}${getStoreCodeAsQueryParams()}`;
        copyToClipboard(generatedUrlWithToken);
        setIsLoading(false);
        // setJwtToken(jwtToken);
      });
  };

  // Todo Remove this deprecated method
  const permissionCheckToEnable = (permission: string) => {
    /*const tenant = _.get(user, 'tenant');
    const isLegacy = _.isEmpty(_.get(tenant, 'casa_product_plans'));
    const roleGroups = _.get(user, 'tenant_role_groups', []);
    const userPermissions = _.flatMap(roleGroups, 'permissions');*/
    // return isLegacy || _.includes(userPermissions, permission);
    return true;
  };

  function TransitionUp(props: any) {
    return <Slide {...props} direction="up" />;
  }

  function copyToClipboard(text: string) {
    const textarea = document.createElement('textarea');
    textarea.textContent = text;
    document.body.appendChild(textarea);
    textarea.id = 'copyToClipboard';
    textarea.select();
    document.execCommand('copy');
    handleClick(TransitionUp);
  }

  function handleClick(Transition: any) {
    setTransition(() => Transition);
    setOpen(true);
  }

  function handleClose() {
    setOpen(false);
  }

  function isOwnStoreUser(storeCode: number) {
    if (isEditable) {
      return true;
    }
    const storeId = _.flatMap(stores, 'id');
    return _.includes(storeId, storeCode) || isNull(storeCode);
  }

  function FlipbookList(props: any) {
    return (
      <>
        <div className={'flipbook-listing-container'}>
          <div className={'flipbook-listing'}>
            {permissionCheckToEnable('create_flipbook_') && (
              <div style={{ marginBottom: '3rem' }}>
                <div
                  onClick={() => redirectToCreate()}
                  className={`flpbk-action-btn flipbook-create-btn`}
                >
                  <AddCircleOutlineSharpIcon
                    fontSize="small"
                    className="flipbookCardBtnIcon"
                    style={{ marginBottom: '1px' }}
                  />
                  Create New Flipbook
                </div>
              </div>
            )}

            {isLoading && (
              <div style={{ color: '#F2755F' }}>
                <CircularProgress color="inherit" />
              </div>
            )}
            {!isLoading && (
              <div className={'flipbook-items'}>
                {flipbooks.map((flipbook, index) => (
                  <FlipbookCard
                    key={index}
                    flipbook={flipbook}
                    redirectToEdit={redirectToEdit}
                    redirectToView={redirectToView}
                    getFlipbookURL={getFlipbookURL}
                    isEditable={isOwnStoreUser(
                      _.get(flipbook, 'tenantStoreId', '')
                    )}
                    hasPermission={permissionCheckToEnable}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
        <Snackbar
          open={open}
          onClose={handleClose}
          TransitionComponent={transition}
          message="Link copied to clipboard"
          autoHideDuration={1500}
        />
      </>
    );
  }

  function SubscribeDialog(props: any) {
    return (
      <div className={'flipbook-listing-container-new'}>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          className={classes.modal}
          open={!allowAccess}
        >
          <div className={classes.paper}>
            <h5> Renew your subscription to enjoy our services.</h5>
          </div>
        </Modal>
      </div>
    );
  }

  return <>{allowAccess ? <FlipbookList /> : <SubscribeDialog />}</>;
};

export default FlipbookListing;
