import React, { FC, useContext, useState } from 'react';
import SelectWithAutoComplete from '../SelectWithAutoComplete/SelectWithAutoComplete';
import _ from 'lodash';
import './SearchProducts.css';
import ProductCard from '../ProductCard/ProductCard';
import { BusinessUnitContext } from '../../OfferDefinition/BusinessUnitContext';
import { TileDataCollection } from '../../../models/FlipbookV2/TileData/TileDataCollection.model';

interface SearchProductsProps {
  allProducts: any;
}

export const SearchProducts: FC<SearchProductsProps> = ({ allProducts }) => {
  const [selectedProducts, setSelectedProducts] = useState([]);
  const businessUnitContext = useContext(BusinessUnitContext);
  const productHierarchy = _.get(
    businessUnitContext,
    'business.productHierarchy',
    []
  );

  const options = _.map(allProducts, p => {
    const name = _.get(p, 'extendedData.name', '');
    return {
      label: name,
      value: name,
      productFieldAccessor: 'name'
    };
  });

  function onChangeSelectedProducts(products: any) {
    setSelectedProducts(products);
    getSelectedProducts();
  }

  function getSelectedProducts() {
    return _.filter(allProducts, p =>
      _.includes(
        _.map(selectedProducts, s => _.get(s, 'value')),
        p.extendedData.name
      )
    );
  }

  return (
    <div className={'search-product-container'}>
      <h3 className={'flipbook-popover-text-header uppercase'}>
        Filter Products
      </h3>
      <div className={'search-box'}>
        <SelectWithAutoComplete
          onChange={e => onChangeSelectedProducts(e)}
          options={options}
          values={selectedProducts}
          label={'Products'}
          size={'auto'}
        />
      </div>
      <div className="result-line">Result</div>
      {getSelectedProducts().length >= 1 && (
        <div className={'product-listing-container'}>
          <div className={'product-listing-grid'}>
            {_.map(getSelectedProducts(), p => {
              return (
                <div>
                  <ProductCard
                    isStockLoaded={false}
                    tileDataCollection={
                      new TileDataCollection(
                        '',
                        [],
                        false,
                        [],
                        [],
                        [],
                        { title: '', subTitle: '' },
                        []
                      )
                    }
                    isProductLoaded={false}
                    productHierarchy={productHierarchy}
                    allProducts={allProducts}
                  />
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchProducts;
