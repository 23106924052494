import React, { FC, useCallback, useEffect, useState } from 'react';
import { IconButton, Popover } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import LinkIcon from '@material-ui/icons/Link';
import { anchorOriginObj, getColor, transformOriginObj } from './utiles';
import Slide from '@material-ui/core/Slide';
import Snackbar from '@material-ui/core/Snackbar';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import useCurrentUser from '../../../hooks/useCurrentUser';
import _, { isEmpty } from 'lodash';
import { Business } from '../../../models/User.model';
import flipbookService from '../../../services/flipbook.service';
import config from '../../../config';

interface SharePopoverProps {
  isCustomerView: boolean;
  flipbookIdOrToken: any;
  currentStore: any;
}
export const SharePopover: FC<SharePopoverProps> = ({
  isCustomerView,
  flipbookIdOrToken,
  currentStore
}) => {
  const [anchorEl, setAnchorEl] = React.useState<any>(null);
  const [isOpen, setIsOpen] = React.useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [transition, setTransition] = useState(undefined);
  const [currentUrl, setCurrentUrl] = useState<any>();
  const sharePopoverId = isOpen ? 'simple-popover' : undefined;
  const user = useCurrentUser();
  const business =
    _(user.getBusinesses())
      .orderBy(['id'])
      .first() || new Business();

  const getStoreQueryParams = useCallback(store => {
    if (isEmpty(store)) {
      return '';
    } else {
      return `store=${store}`;
    }
  }, []);

  useEffect(() => {
    if (isCustomerView) {
      setCurrentUrl(window.location.href);
    } else {
      const businessUnitId = _.get(business, 'id');
      const tenantId = _.get(user, 'id');
      const tenantEsKey = _.get(user, 'tenant.esKey');
      const store = _.get(currentStore, 'store', '');
      if (businessUnitId !== 0 && tenantId !== 0 && !_.isEmpty(tenantEsKey)) {
        flipbookService
          .getFlipbookJwtTokenById(
            tenantId,
            tenantEsKey,
            parseInt(flipbookIdOrToken),
            businessUnitId
          )
          .then(jwtToken => {
            const storeQueryParams = getStoreQueryParams(store);
            const generatedUrlWithToken = `${
              config.casaWebAppUrl
            }/flipbook/customer-view/${_.get(
              jwtToken,
              'jwt'
            )}?${storeQueryParams}`;
            setCurrentUrl(generatedUrlWithToken);
          });
      }
    }
  }, [
    business,
    currentStore,
    flipbookIdOrToken,
    getStoreQueryParams,
    isCustomerView,
    user
  ]);
  const onClickOpen = (value: any) => {
    setIsOpen(true);
    setAnchorEl(value);
  };

  function TransitionUp(props: any) {
    return <Slide {...props} direction="up" />;
  }

  function handleClick(Transition: any) {
    setTransition(() => Transition);
    setSnackbarOpen(true);
  }

  function handleClose() {
    setSnackbarOpen(false);
  }

  const whatsappShare = () => {
    return `//wa.me/?text=${currentUrl}`;
  };

  function sharePopoverComponent() {
    return (
      <div className={'flipbook-settings-popover-container'}>
        <div className={'my-4'}>
          <h3 className={'flipbook-popover-text-header uppercase'}>
            Share Options
          </h3>
        </div>
        <div className={'my-4'}>
          <CopyToClipboard
            text={currentUrl}
            onCopy={e => handleClick(TransitionUp)}
          >
            <div className={'flipbookLink'}>
              Copy Link
              <LinkIcon fontSize="small" className="ml-1" />
            </div>
          </CopyToClipboard>

          <div
            className={'flipbookLink'}
            onClick={() => window.open(whatsappShare(), '_blank')}
          >
            Whatsapp
            <LinkIcon fontSize="small" className="ml-1" />
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className={'flipbook-bar-icon'}>
        <IconButton
          style={{ color: getColor(isOpen) }}
          className={'outline-none'}
          aria-label="helpOutlineIcon"
          onClick={e => onClickOpen(e.currentTarget)}
        >
          <ShareIcon />
        </IconButton>
      </div>
      <Popover
        id={sharePopoverId}
        open={isOpen}
        anchorEl={anchorEl}
        onClose={e => setIsOpen(false)}
        anchorOrigin={anchorOriginObj}
        transformOrigin={transformOriginObj}
      >
        {sharePopoverComponent()}
      </Popover>
      <Snackbar
        open={snackbarOpen}
        onClose={handleClose}
        TransitionComponent={transition}
        message="Link copied to clipboard"
        autoHideDuration={1500}
      />
    </div>
  );
};

export default SharePopover;
