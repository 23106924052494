import config from '../../config/index';
import { getKeycloakHeaders } from '../../keycloak';
export const getLeadSources = (businessUnitId: number) => {
  return fetch(
    `${config.apiServerUrl}/users/walk-in-interest/businessUnit/${businessUnitId}/lead-sources`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(res => res.json());
};

export const getStoreUsers = (
  permissions: string[],
  businessUnitId: number
) => {
  const queryParams = new URLSearchParams();
  permissions.forEach(permission => {
    queryParams.append('permissions', permission);
  });
  return fetch(
    `${config.apiServerUrl}/users/walk-in-interest/businessUnit/${businessUnitId}/lead-users`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json'
      }
    }
  ).then(res => res.json());
};

export const createLeadReport = (
  reportType: string,
  filters: any,
  businessUnitId: number | undefined
) => {
  let queryParams = new URLSearchParams();
  const { startDate, endDate, sources, stores, storePersons } = filters;

  sources.forEach((source: string) => {
    queryParams.append('sources', source);
  });

  stores.forEach((store: string) => {
    queryParams.append('stores', store);
  });

  storePersons.forEach((storePerson: string) => {
    queryParams.append('storePersons', storePerson);
  });
  queryParams.append('startDate', startDate);
  queryParams.append('endDate', endDate);

  return fetch(
    `${config.ruleServerUrl}/users/reports/download/${reportType}/${businessUnitId}?${queryParams}`,
    {
      credentials: 'include',
      method: 'GET',
      headers: {
        'Content-type': 'application/json',
        ...getKeycloakHeaders()
      }
    }
  ).then(r => r.json());
};

export const getLeadReportDownload = ({
  page,
  offset,
  filters
}: {
  page: number;
  offset: number;
  filters?: any;
}): Promise<any> => {
  return fetch(
    `${config.ruleServerUrl}/users/reports/view/downloads/${filters}?offset=${offset}&page=${page}`,
    { credentials: 'include' }
  ).then(res => res.json());
};
