import { ImageType, TileDataImage } from './TileData/TileDataImage.model';
import { TileDataCollection } from './TileData/TileDataCollection.model';
import { TileDataVideo } from './TileData/TileDataVideo.model';
import { get } from 'lodash';

export enum TileType {
  TileOne = 'TILEONE',
  TileTwo = 'TILETWO'
}
export enum TileDataType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
  COLLECTION = 'COLLECTION'
}
export class FlipbookLayoutTile {
  public tileType: TileType;
  public layoutId: number;
  public tileDataType: TileDataType;
  public tileData: TileDataVideo | TileDataCollection | TileDataImage;
  public id?: number;

  constructor(
    tileType: TileType,
    tileDataType: TileDataType,
    tileData: TileDataVideo | TileDataCollection | TileDataImage | undefined,
    layoutId: number,
    id?: number
  ) {
    this.tileType = tileType;
    this.layoutId = layoutId;
    this.tileDataType = tileDataType;
    this.tileData = this.setTileData(tileData);
    this.id = id;
  }

  private setTileData(
    tileData: TileDataVideo | TileDataCollection | TileDataImage | undefined
  ) {
    switch (this.tileDataType) {
      case TileDataType.IMAGE:
        return new TileDataImage(
          get(tileData, 'imageType', ImageType.PLAIN),
          get(tileData, 'imageData', {})
        );
      case TileDataType.VIDEO:
        return new TileDataVideo(
          get(tileData, 'URL', ''),
          get(tileData, 'isMuted', true),
          get(tileData, 'isAutoPlay', true),
          get(tileData, 'isControlsEnabled', true)
        );
      case TileDataType.COLLECTION:
        return new TileDataCollection(
          get(tileData, 'title', ''),
          get(tileData, 'skus', []),
          get(tileData, 'isShowTitleOnFlipbook', false),
          get(tileData, 'productHierarchyTreeChecked', []),
          get(tileData, 'productHierarchyTreeExpanded', []),
          get(tileData, 'productsFilteredByFirstHierarchy', []),
          get(tileData, 'productCardTemplate', { title: '', subTitle: '' }),
          get(tileData, 'currentPageSKUS', [])
        );
      default:
        return new TileDataImage(
          get(tileData, 'imageType', ImageType.PLAIN),
          get(tileData, 'imageData', [])
        );
    }
  }
}
