import './styles/common.scss';
import 'bootstrap/dist/css/bootstrap.css';
import 'react-toastify/dist/ReactToastify.css';

import { MuiThemeProvider } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import { ReactKeycloakProvider } from '@react-keycloak/web';
import React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { CampaignDetails, CampaignReports } from './components/Campaign';
import CromaRseReferral from './components/CromaRseReferral';
import FlipbookCreate from './components/Flipbook/FlipbookCreate';
import FlipbookDemo from './components/Flipbook/FlipbookDemo';
import FlipbookListing from './components/Flipbook/FlipbookListing';
import Channels from './components/GeneralSetting/Channels';
import JourneyBuilder from './components/JourneyBuilder/JourneyBuilder';
import JourneyEdit from './components/JourneyBuilder/JourneyEdit/JourneyEdit';
import JourneyList from './components/JourneyBuilder/JourneyListing/JourneyList/JourneyList';
import KamExpense from './components/KamExpense';
import CreateLabel from './components/Labeling';
import PdfDocument from './components/Labeling/components/pdf';
import { OfferMaster } from './routes/OfferMaster';
import { PrivateRoute } from './utils/ProtectedRoute';
import PdfDocumentSample from './components/Labeling/components/test-pdf';
import PrintLabel from './components/Labeling/PrintLabel';
import LiveReports from './components/LiveReports';
import DropDownConfig from './components/LMS/DropDownConfig/DropDownConfig';
import LoyaltyConfig from './components/LoyaltyConfig';
import LoyaltyException from './components/LoyaltyException';
import LoyaltyPoints from './components/LoyaltyPoints/LoyaltyPoints';
import LoyaltyRedeemConfiguration from './components/LoyaltyRedeemConfiguration/LoyaltyRedeemConfiguration';
import theme from './components/OfferDefinition/theme';
import { OfferDetail } from './components/OfferDetail';
import Offerio from './components/Offerio/Offerio';
import OfferSearch from './components/OfferSearch/OfferSearch';
import OTPConfiguration from './components/OTPConfiguration';
import ProductManagement from './components/ProductManagement';
import PWAConfig from './components/PWAConfig/PWAConfig';
import RoleAssign from './components/RoleAssign';
import RoleAssign2 from './components/RoleAssign2';
import SendCampaignMessagePage from './components/SendCampaignMessagePage';
import Setting from './components/Setting/Setting';
import SocialMediaRewardDefinition from './components/SocialMediaRewardDefinition';
import SocialMediaRewardList from './components/SocialMediaRewardList';
import StoreManagement from './components/StoreManagement/StoreManagement';
import SubscriptionView from './components/SubscriptionView';
import UserListing from './components/UserListing';
import keycloak from './keycloak';
import ToastMessage from './reusable/ToastMessage';
import { CustomerOffersRoute } from './routes/CustomerOffersRoute';
import { OfferCartBuilderRoute } from './routes/OfferCartBuilderRoute';
import { OfferViewRoute } from './routes/OfferViewRoute';

/* eslint-disable import/no-extraneous-dependencies */
import { CloneOfferRoute } from './routes/CloneOfferRoute';
import InvoiceGenerator from './components/DynamicInvoiceGenerator/InvoiceGenerator';
import InvoiceCreator from './components/DynamicInvoiceCreator/InvoiceCreator';
import ReferralConfiguration from './components/ReferralConfiguration';
import CreateCustomerGroup from './components/CustomerGroup/CreateCustomerGroup';
import EditCustomerGroup from './components/CustomerGroup/EditCustomerGroup';
import B2BOffersReport from './components/B2BOffersReport/B2BOffersReport';
import TransactionWiseReport from './components/TenantReports/TransactionReport';
// import TransactionWiseReportDownload from './components/TenantReports/TransactionWiseReportDownload/TransactionWiseReportDownload';
import FraudReport from './components/TenantReports/FraudReport';
import MemberLoyaltyReport from './components/TenantReports/MemberReport/LoyaltyPoints';
import UnblockLoyaltyReport from './components/TenantReports/UnblockLoyaltyReport';
import MemberBillReport from './components/TenantReports/MemberReport/BillWise';
import StoreHealthDashboard from './components/TenantReports/StoreHealthDashboard';
import CampaignTenantReport from './components/TenantReports/CampaignReport';
import MigrateCustomer from './components/MigrateCustomer';
import KPIReport from './components/TenantReports/KPIReport';
import OfferTenantReport from './components/TenantReports/OfferReport';
import OfferPromoTenantReport from './components/TenantReports/OfferPromoReport';
import TierDowngradeTenantReport from './components/TenantReports/TierDowngradeReport';
import LoyaltySignUp from './components/PWAConfig/LoyaltySignUp';
import CasaFields from './components/CasaFields/components/TenantFields';
import LiabilitySummaryReport from './components/TenantReports/LiabilitySummaryReport';
import RFMReport from './components/TenantReports/RFMReport';
import CampaignOverallReports from './components/Campaign/pages/OverallCampaignReport/CampaignReports';
import JournifySettings from './components/JournifySettings/JournifySettings';
import StoreKPIReport from './components/TenantReports/StoreKPIReport';
import LeadReport from './components/TenantReports/LeadReport';

const App = props => {
  const { toastMessageState } = props.toastMessageStateReducer;
  return (
    <ReactKeycloakProvider
      authClient={keycloak}
      LoadingComponent={
        <div
          style={{
            color: '#F2755F',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
            position: 'fixed',
            width: '100%'
          }}
        >
          <CircularProgress
            color={'inherit'}
            variant={'indeterminate'}
            size={60}
            thickness={5}
          />
        </div>
      }
    >
      <ToastMessage toastMessageState={toastMessageState} />
      <ToastContainer position="top-center" />
      <MuiThemeProvider theme={theme.theme}>
        <Route path="/offers/create" component={() => <OfferDetail />} />
        <Route
          path="/offers/view/:offerCode"
          component={() => <OfferViewRoute />}
        />
        <Route
          path="/offers/clone-offer/:offerCode"
          component={() => <CloneOfferRoute />}
        />

        <Route
          path="/customer-offers/:customerMobile"
          component={() => <CustomerOffersRoute />}
        />
        <Route
          path="/build-cart/:customerMobile/:offerCode"
          component={() => <OfferCartBuilderRoute />}
        />
        <Route
          path="/social-media/create"
          component={() => <SocialMediaRewardDefinition />}
        />
        <Route
          path="/social-media"
          exact
          component={() => <SocialMediaRewardList />}
        />
        <Route
          path="/send-link"
          component={() => <SendCampaignMessagePage />}
        />
        <Route path="/rse-referral" component={() => <CromaRseReferral />} />
        <Route
          exact
          path="/flipbook"
          component={({ match }) => <FlipbookListing {...{ match }} />}
        />
        <Route
          path="/flipbook/:mode/:flipbookIdOrToken"
          component={() => <FlipbookDemo />}
        />
        <Route path="/kam-expense/:sub" component={KamExpense} />
        <Route
          path="/flipbook/create"
          component={() => (
            <FlipbookCreate
              redirectToEdit={id => {
                window.location.href = `/flipbook/edit/${id}`;
              }}
            />
          )}
        />
        <PrivateRoute path="/labeling" component={CreateLabel} />
        <PrivateRoute path="/print-label" component={PrintLabel} />
        <PrivateRoute path="/subscription-view" component={SubscriptionView} />
        <Route path="/pdf" component={() => <PdfDocument />} />
        <Route
          path="/sample-backend-pdf-6187745448"
          component={() => <PdfDocumentSample />}
        />
        <Route
          path="/b2b-offers-report"
          component={() => <B2BOffersReport />}
        />
        <Route path="/invoice" component={InvoiceGenerator} />
        <Route path="/invoice-create" component={InvoiceCreator} />
        <Route exact path="/offers" component={OfferMaster} />
        <Route exact path="/offers/search" component={OfferSearch} />
        <Route path="/journey/create" component={JourneyBuilder} />
        <Route path="/journey/edit/:id" component={JourneyEdit} />
        <Route path="/journey/list" component={JourneyList} />
        <Route path="/user-list" component={UserListing} />
        <Route path="/loyalty-config" component={LoyaltyConfig} />
        <Route path="/general-setting" component={Setting} />
        <Route path="/loyalty-exception" component={LoyaltyException} />
        <Route path="/role-assign-v1" component={RoleAssign} />
        <Route path="/role-assign-v2" component={RoleAssign2} />
        <Route path="/channels" component={Channels} />
        <Switch>
          <Route exact path="/" component={JournifySettings} />
          <Route path="/journify/settings/email" component={JournifySettings} />
        </Switch>
        <Route
          path="/loyalty-redeem-configuration"
          component={LoyaltyRedeemConfiguration}
        />
        <Route path="/ad-hoc-points" component={LoyaltyPoints} />
        <Route path="/offerio-setup" component={Offerio} />
        <Route path="/store-management" component={StoreManagement} />
        <Route path="/product-management" component={ProductManagement} />
        <Route path="/live-reports" component={LiveReports} />
        <Route path="/otp-configuration" component={OTPConfiguration} />
        <Route
          path="/referral-configuration"
          component={ReferralConfiguration}
        />
        <Route
          path="/reports/tier-downgrade-report"
          exact
          component={TierDowngradeTenantReport}
        />
        <Route
          path="/lms-dropdown-config"
          component={() => <DropDownConfig />}
        />
        <Route path="/personify" exact component={PWAConfig} />
        <Route
          path="/personify/loyalty-signUp"
          exact
          component={LoyaltySignUp}
        />

        {/* create customer group route */}
        <Route path="/create-customer-group" component={CreateCustomerGroup} />
        <Route
          path="/edit-customer-group/:customerGroupId"
          component={EditCustomerGroup}
        />
        <Route
          path="/reports/transaction-report"
          exact
          component={TransactionWiseReport}
        />
        <Route
          path="/reports/offer-report"
          exact
          component={OfferTenantReport}
        />
        <Route
          path="/reports/offer-promo-report"
          exact
          component={OfferPromoTenantReport}
        />
        {/* <Route
            path="/reports/transaction-report/download"
            exact
            component={TransactionWiseReportDownload}
          /> */}
        <Route path="/campaign-analytics" exact component={CampaignDetails} />
        <Route
          path="/campaign-analytics/:campaignId"
          exact
          component={CampaignReports}
        />
        <Route
          path="/campaign-analytics/overall-reports"
          exact
          component={CampaignOverallReports}
        />
        <Route path="/reports/fraud-report" exact component={FraudReport} />
        <Route path="/reports/kpi-report" exact component={KPIReport} />
        <Route path="/reports/rfm-report" exact component={RFMReport} />
        <Route
          path="/reports/store-kpi-report"
          exact
          component={StoreKPIReport}
        />
        <Route
          path="/reports/member-loyalty-report"
          exact
          component={MemberLoyaltyReport}
        />
        <Route
          path="/reports/member-bill-report"
          exact
          component={MemberBillReport}
        />
        <Route
          path="/reports/unblock-loyalty-report"
          exact
          component={UnblockLoyaltyReport}
        />
        <Route
          path="/reports/store-health-report"
          exact
          component={StoreHealthDashboard}
        />
        <Route
          path="/reports/loyalty-liability-report"
          exact
          component={LiabilitySummaryReport}
        />

        <Route
          path="/reports/campaign-report"
          exact
          component={CampaignTenantReport}
        />
        <Route
          path="/reports/overall-lead-report"
          exact
          render={props => <LeadReport {...props} type="overall_lead_report" />}
        />
        <Route
          path="/reports/store-efficiency-report"
          exact
          render={props => (
            <LeadReport {...props} type="store_efficiency_report" />
          )}
        />
        <Route
          path="/reports/store-person-efficiency-report"
          exact
          render={props => (
            <LeadReport {...props} type="store_person_efficiency_report" />
          )}
        />
        <Route
          path="/reports/source-efficiency-report"
          exact
          render={props => (
            <LeadReport {...props} type="source_efficiency_report" />
          )}
        />
        <Route
          path="/reports/product-hierarchy-efficiency-report"
          exact
          render={props => (
            <LeadReport {...props} type="product_hierarchy_efficiency_report" />
          )}
        />
        <Route path="/migrate-customers" exact component={MigrateCustomer} />
        <Route path="/tenant-field-mapping" exact component={CasaFields} />
      </MuiThemeProvider>
    </ReactKeycloakProvider>
  );
};

const mapStateToProps = state => {
  return state;
};

export default connect(mapStateToProps)(App);
