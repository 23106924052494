import { WithClassName } from './offer/WithClassName.model';
import { Offer } from './offer/Offer.model';
import update from 'immutability-helper';
import _ from 'lodash';
import { Bucket, Discount } from './offer/OfferRule.model';

export class Product extends WithClassName {
  public id = '';
  public price = NaN;
  public tenantId = 0;
  public tenantBrandBusinessId = 0;
  public extendedData: { [key: string]: string } = {};
  // todo: not rely on master data, should be using only extended data after sync - Ragavan
  public masterData: { [key: string]: string } = {};

  constructor() {
    super('Product');
  }

  public isValid() {
    return this.id !== '' && !isNaN(this.price);
  }

  public getDiscountedPrice(discount: Discount) {
    if (discount.value) {
      return this.price - discount.getDiscountAmount(this.price);
    } else {
      return this.price;
    }
  }

  public get sku(): string {
    return _.get(this.extendedData, 'sku');
  }

  public get imageUrl(): string {
    return _.get(
      this.extendedData,
      'imageUrls[0]',
      `https://imagineonline.casa.ample.co.in/ample_img?sku=${this.sku}`
    );
  }

  public get series(): string {
    return (
      _.get(this.extendedData, 'parentProductId') ||
      _.get(this.extendedData, 'ecommProductId') ||
      _.get(this.extendedData, 'category') ||
      _.get(this.masterData, 'camprcat3')
    );
  }

  public get storage(): string {
    return _.get(this.extendedData, 'storage');
  }

  public get color(): string {
    return _.get(this.extendedData, 'color');
  }

  public get description(): string {
    return (
      _.get(this.extendedData, 'description') ||
      _.get(this.extendedData, 'camprdesc')
    );
  }

  public get size(): string {
    return _.get(this.extendedData, 'size');
  }

  public get variantOptionsType(): string {
    return _.toLower(_.get(this.extendedData, 'masterCategory'));
  }

  public get name(): string {
    return (
      _.get(this.extendedData, 'name') ||
      _.get(this.extendedData, 'displayName', '') ||
      _.get(this.extendedData, 'description', '')
    );
  }
}

export class OrderItem extends WithClassName {
  public offerCode?: string;
  public bucketId?: number;
  public skuCode?: string;
  public productId?: number;
  public quantity = 1;

  public discountAmount?: number;
  public price?: number;
  public hasOffer = false;

  constructor() {
    super('OrderItem');
  }

  public static createFrom(
    offer: Offer,
    bucket: Bucket,
    product: Product
  ): OrderItem {
    return _.extend(new OrderItem(), {
      offerCode: offer.code,
      bucketId: bucket.bucketId,
      skuCode: product.sku,
      productId: product.id,
      price: product.price,
      hasOffer: true
    });
  }
}

export class CartInfo extends WithClassName {
  public customerMobile?: string;
  public customerEmail?: string;

  public storeName?: string;
  public storeUserEmail?: string;

  public channel = 'POS';
  public billDate: Date = new Date();
  public billType = 'Retail';
  public billAmount?: number;
  public advanceDate?: Date;

  public orderItems: OrderItem[] = [];

  constructor() {
    super('CartInfo');
  }

  public upsertOfferProduct(
    offer: Offer,
    bucket: Bucket,
    product: Product
  ): CartInfo {
    const orderItems = _.filter(
      this.orderItems,
      o => o.bucketId !== bucket.bucketId
    );
    const newOrderItem = OrderItem.createFrom(offer, bucket, product);
    return update(this as CartInfo, {
      orderItems: {
        $set: [...orderItems, newOrderItem]
      }
    });
  }
}

export class CartWrapper extends WithClassName {
  public cartId: number;
  public tenantId: number;
  public tenantBrandBusinessId: number;

  public cartInfo: CartInfo;
  public shipmentInfo: object;
  public addressInfo: object;

  constructor(cartId = 0, cartInfo: CartInfo = new CartInfo()) {
    super('CartWrapper');
    this.tenantBrandBusinessId = 0;
    this.tenantId = 0;
    this.cartId = cartId;
    this.cartInfo = cartInfo;
    this.shipmentInfo = {
      isIGST: false
    };
    this.addressInfo = {
      billingAddress: {
        address: {}
      },
      shippingAddress: {
        address: {}
      }
    };
  }

  public isFilled(offer: Offer) {
    const bucketIds = _.map(
      offer.offerDefinition.bucket.buckets,
      b => b.bucketId
    );
    return _.every(bucketIds, bId =>
      _.some(this.cartInfo.orderItems, o => o.bucketId === bId)
    );
  }

  public upsertOfferProduct(
    offer: Offer,
    bucket: Bucket,
    product: Product
  ): CartWrapper {
    return update(this as CartWrapper, {
      cartInfo: {
        $set: this.cartInfo.upsertOfferProduct(offer, bucket, product)
      }
    });
  }
}
