export class FlipbookUrlHash {
  private isCustomerView: boolean;
  private isEditMode: boolean;
  private flipbookIdOrToken: string;
  private history: any;

  constructor(
    isCustomerView: boolean,
    isEditMode: boolean,
    flipbookIdOrToken: string,
    history: any
  ) {
    this.isCustomerView = isCustomerView;
    this.isEditMode = isEditMode;
    this.flipbookIdOrToken = flipbookIdOrToken;
    this.history = history;
  }

  private getCurrentPath() {
    const mode = this.isCustomerView
      ? 'customer-view'
      : this.isEditMode
      ? 'edit'
      : 'view';
    return `flipbook/${mode}/${this.flipbookIdOrToken}`;
  }

  public setHistoryHash(pageNumber: number) {
    const currentPath = this.getCurrentPath();
    this.history.push({ pathname: `/${currentPath}/page/${pageNumber}` });
  }
}
