import React, { FC, useState } from 'react';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import downloadIcon from '../../Assets/images/download_icon.svg';
import { getAbsolutePath } from '../Utiles/GroupedSelectedProducts';
import _, { concat, map, uniq } from 'lodash';
import { Button, CircularProgress, IconButton } from '@material-ui/core';
import flipbookService from '../../../services/flipbook.service';
import '../Flipbook.css';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { TileDataCollection } from '../../../models/FlipbookV2/TileData/TileDataCollection.model';

interface BulkUploadProps {
  productHierarchy: any;
  tileData: TileDataCollection;
  setTileData: (updatedTileData: TileDataCollection) => void;
  selectedMatchingProducts: any;
}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& > *': {
        margin: theme.spacing(1)
      }
    },
    input: {
      display: 'none'
    }
  })
);

const BulkUpload: FC<BulkUploadProps> = ({
  productHierarchy,
  tileData,
  setTileData,
  selectedMatchingProducts
}) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [errorData, setErrorData] = useState<Array<string>>([]);

  function downloadCsvTemplate() {
    const headers = 'product_id';
    const csvData = new Blob([headers], { type: 'text/csv;charset=utf-8;' });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `sample_bulk_products.csv`);
    tempLink.click();
  }

  function downloadErrorCsv() {
    const headers = 'product_id';
    const rows = _.concat(headers, errorData).join('\n');
    const csvData = new Blob([rows], {
      type: 'text/csv;charset=utf-8;'
    });
    const csvURL = window.URL.createObjectURL(csvData);
    const tempLink = document.createElement('a');
    tempLink.href = csvURL;
    tempLink.setAttribute('download', `error_products.csv`);
    tempLink.click();
  }
  const handleProductUpload = (val: any) => {
    setIsLoading(true);
    flipbookService.getProductsByCSV(val[0]).then(res => {
      let updatedTileData = tileData;
      const successData = _.get(res, 'data.successData', []);
      const errorData = _.get(res, 'data.errorData', []);
      const getAllProductsWithPath = _.map(successData, v => {
        const lastHierarchy = _.last(productHierarchy);
        const productLastNodeValue = _.get(v, `extendedData.${lastHierarchy}`);
        const sku = _.get(v, 'extendedData.sku');
        return {
          path: getAbsolutePath(
            {
              id: sku
            },
            [v],
            _.take(productHierarchy, productHierarchy.length - 1)
          ),
          id: sku,
          label: productLastNodeValue,
          value: productLastNodeValue
        };
      });
      const groupedProductsByPath = _.groupBy(getAllProductsWithPath, 'path');
      const updatedData = _.map(groupedProductsByPath, (v, k) => {
        return { path: k, selectedProducts: v };
      });
      updatedTileData = {
        ...updatedTileData,
        productHierarchyTreeChecked: _.map(updatedData, u => u.path)
      };
      const sku = map(successData, 'extendedData.sku');
      const updatedMatchingProducts = uniq(
        concat(sku, selectedMatchingProducts)
      );
      updatedTileData = {
        ...updatedTileData,
        skus: updatedMatchingProducts
      };
      setTileData(updatedTileData);
      setErrorData(_.map(errorData, 'sku'));
      setIsLoading(false);
    });
  };
  const getAllSelectedMatchingProducts = () => {
    return _.flatMap(selectedMatchingProducts, v => v.selectedProducts);
  };
  return (
    <div className={'product-selection-section'}>
      <div className={'flipbook-csv-upload'}>
        <div className={classes.root}>
          <input
            accept=".csv"
            className={classes.input}
            id="contained-button-file"
            multiple
            type="file"
            onChange={e => handleProductUpload(e.target.files)}
            disabled={isLoading}
          />
          <label htmlFor="contained-button-file">
            <Button
              variant="outlined"
              size="small"
              color="primary"
              component="span"
              startIcon={<CloudUploadIcon />}
              disabled={isLoading}
            >
              Upload CSV
            </Button>
          </label>
        </div>
        {isLoading && (
          <div>
            <div className={'flipbook-loader bulk-upload-loader'}>
              <CircularProgress
                color="inherit"
                variant="indeterminate"
                size={15}
                thickness={3}
              />
            </div>
          </div>
        )}
      </div>
      <p className={'selected-products-label'}>
        <span className={'selected-products-label-highlight'}>{`${
          getAllSelectedMatchingProducts().length
        } products`}</span>
        {'selected'}
      </p>
      <div className={'flipbook-csv-template-container'}>
        <div>
          {!_.isEmpty(errorData) && (
            <span
              className="flipbook-sample-temp flipbook-csv-error-text"
              onClick={() => downloadErrorCsv()}
            >
              {`${errorData.length} error data`}
              <IconButton
                color="primary"
                aria-label="upload picture"
                component="span"
                style={{ padding: 4 }}
              >
                <img src={downloadIcon} alt={'imge add'} />
              </IconButton>
            </span>
          )}
        </div>
        <div>
          <span
            className="flipbook-sample-temp"
            onClick={() => downloadCsvTemplate()}
          >
            CSV file template
            <IconButton
              color="primary"
              aria-label="upload picture"
              component="span"
              style={{ padding: 4 }}
            >
              <img src={downloadIcon} alt={'imge add'} />
            </IconButton>
          </span>
        </div>
      </div>
    </div>
  );
};

export default BulkUpload;
