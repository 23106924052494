import React, { FC, useContext, useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import _ from 'lodash';
import { UserContext } from '../../../hooks/UserContext';
import { isEcomUserLoggedIn } from '../Utiles/FlipbookUtiles';
import cancelIcon from '../../Assets/images/icons/cancel_icon.svg';
import whatsAppIcon from '../../Assets/images/icons/whats_app_logo.png';
import { LoginFormWithoutOtp } from './LoginWithoutOtp';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    backButton: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
);

function getSteps() {
  return ['Login', 'Shop from Store'];
}

interface CheckoutFormChatAndBuyProps {
  currentStore: any;
  setCartItems: (data: any) => void;
  whatsAppUrl: string;
  handleProductPopUpClose: any;
  setIsCustomerAuthenticated: (data: boolean) => void;
  name: string;
  setName: (name: string) => void;
}

export const CheckoutFormChatAndBuy: FC<CheckoutFormChatAndBuyProps> = ({
  currentStore,
  setCartItems,
  whatsAppUrl,
  handleProductPopUpClose,
  setIsCustomerAuthenticated,
  name,
  setName
}) => {
  const classes = useStyles();
  const steps = getSteps();
  const stepValue = isEcomUserLoggedIn() ? 1 : 0;
  const storeCode = _.get(currentStore, 'store', '');
  const [activeStep, setActiveStep] = useState(0);
  const [mobileNumber, setMobileNumber] = useState('');
  const userInfo = _.get(useContext(UserContext), 'userInfo');
  const [showNavigationBtn, setShowNavigationBtn] = React.useState(true);
  const [isNextEnabled, setIsNextEnabled] = React.useState(true);
  const [showNextOrFinish, setShowNextOrFinish] = React.useState(true);

  useEffect(() => {
    const otpStep = 0;
    if (stepValue === otpStep) {
      setShowNavigationBtn(false);
    }
  }, [stepValue]);

  useEffect(() => {
    const whatsAppStep = 1;
    activeStep === whatsAppStep
      ? setShowNextOrFinish(false)
      : setShowNextOrFinish(true);
  }, [activeStep]);

  useEffect(() => {
    if (isEcomUserLoggedIn()) {
      setIsCustomerAuthenticated(true);
    }
  }, [activeStep, setIsCustomerAuthenticated]);

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  return (
    <div className={'checkout-form-container'}>
      <div className={'popup-header-section popup-no-header-checkout'}>
        <img
          className={'popup-close-section'}
          src={cancelIcon}
          alt={'close'}
          style={{ margin: '1rem' }}
          onClick={handleProductPopUpClose}
        />
      </div>
      <div className={'stepper-container'}>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map(label => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div className={'text-center'}>
            <div>
              <div>
                {activeStep === 0 && (
                  <div>
                    <LoginFormWithoutOtp
                      setCartItems={setCartItems}
                      tenantName={_.get(userInfo, 'esKey', '')}
                      setMobileNumber={setMobileNumber}
                      mobileNumber={mobileNumber}
                      setShowNavigationBtn={setShowNavigationBtn}
                      setIsNextEnabled={setIsNextEnabled}
                      storeCode={storeCode}
                      setSkipLoginStep={e => setActiveStep(e)}
                      name={name}
                      setName={setName}
                    />
                  </div>
                )}
                {activeStep === 1 && (
                  <div>
                    <a
                      href={whatsAppUrl}
                      title="Share on whatsapp"
                      target={'_blank'}
                      rel="noopener noreferrer"
                      className={'chat-and-buy'}
                    >
                      <img
                        className={'whatsApp-icon'}
                        src={whatsAppIcon}
                        alt={'close'}
                        style={{ margin: '1rem' }}
                        onClick={handleProductPopUpClose}
                      />
                    </a>
                  </div>
                )}
              </div>
              <div>
                {showNavigationBtn && (
                  <div>
                    <Button
                      disabled={activeStep === 0}
                      color="primary"
                      onClick={handleBack}
                      className={`step-nav-btn`}
                    >
                      Back
                    </Button>
                    {showNextOrFinish && (
                      <Button
                        disabled={!isNextEnabled}
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={`step-nav-btn`}
                      >
                        {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckoutFormChatAndBuy;
