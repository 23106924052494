import React, { FC, useEffect, useState } from 'react';
import './CheckoutForm.css';
import { Button, CircularProgress, TextField } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import {
  getNameFromNumber,
  loginWithoutOtp
} from '../../../services/ecomCart.service';
import _ from 'lodash';
import { isEcomUserLoggedIn } from '../Utiles/FlipbookUtiles';
import Snackbar from '@material-ui/core/Snackbar';

interface LoginFormProps {
  tenantName: string;
  setCartItems: (data: any) => void;
  setSkipLoginStep: (data: any) => void;
  mobileNumber: any;
  setMobileNumber: (data: any) => void;
  setShowNavigationBtn: (data: boolean) => void;
  setIsNextEnabled: (data: boolean) => void;
  storeCode: any;
  name: string;
  setName: (name: string) => void;
}

export const LoginFormWithoutOtp: FC<LoginFormProps> = ({
  tenantName,
  setCartItems,
  setSkipLoginStep,
  mobileNumber,
  setMobileNumber,
  setShowNavigationBtn,
  setIsNextEnabled,
  storeCode,
  name,
  setName
}) => {
  const [showMobileForm, setShowMobileForm] = React.useState(true);
  const [isUserLoggedIn, setIsUserLoggedIn] = React.useState(
    isEcomUserLoggedIn()
  );
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  useEffect(() => {
    if (isUserLoggedIn) {
      setShowNavigationBtn(true);
      setIsNextEnabled(true);
    }
  }, [isUserLoggedIn, setIsNextEnabled, setShowNavigationBtn]);

  const onClickLogout = () => {
    localStorage.clear();
    setIsUserLoggedIn(false);
    setShowMobileForm(true);
    setCartItems([]);
    setShowNavigationBtn(false);
  };
  const openErrorMessage = (command: any) => {
    setErrorMessage(_.get(command, 'error.message', 'Something went wrong'));
    setOpen(true);
  };
  const onSubmitLogin = () => {
    loginWithoutOtp(mobileNumber, tenantName, storeCode, name).then(
      async res => {
        if (!_.has(res, 'error')) {
          setSkipLoginStep(1);
          setShowNavigationBtn(true);
        } else {
          openErrorMessage(res);
        }
      },
      err => {
        openErrorMessage(err);
      }
    );
  };
  const onChangeMobileNumber = (num: string) => {
    setMobileNumber(num);
    if (num.length === 10) {
      setIsLoading(true);
      getNameFromNumber(num, tenantName).then(
        res => {
          if (!_.has(res, 'error')) {
            setName(_.get(res, 'message.name', ''));
          } else {
            openErrorMessage(res);
          }
          setIsLoading(false);
        },
        err => {
          openErrorMessage(err);
          setIsLoading(false);
        }
      );
    }
  };
  function handleClose() {
    setOpen(false);
  }

  return (
    <div className={'login-form-container'}>
      <div>
        {isUserLoggedIn ? (
          <div>
            <Button
              variant="contained"
              color="primary"
              className={'mx-2 change-number-btn'}
              onClick={() => onClickLogout()}
            >
              {'Change My Number'}
            </Button>
            <Alert className={'change-number-alert'} severity="warning">
              Warning — Your Current Cart Information Will Be Lost !
            </Alert>
          </div>
        ) : (
          <div>
            {showMobileForm && (
              <div className={'login-form-grid'}>
                <div className={'login-form-flex'}>
                  <div className={'login-form-input-field'}>
                    <TextField
                      id="outlined-number"
                      label="Mobile Number"
                      type="number"
                      InputLabelProps={{
                        shrink: true
                      }}
                      variant="outlined"
                      value={mobileNumber}
                      onChange={e => {
                        const value = e.target.value;
                        onChangeMobileNumber(value);
                      }}
                    />
                  </div>
                  <div className={'login-form-validator-field'}></div>
                </div>
                <div className={'login-form-flex'}>
                  <div className={'login-form-input-field'}>
                    <TextField
                      id={'outlined-name'}
                      label={'Name'}
                      type={'text'}
                      variant={'outlined'}
                      value={name}
                      onChange={e => {
                        const value = e.target.value;
                        setName(value);
                      }}
                    />
                  </div>
                  <div className={'login-form-validator-field'}>
                    {isLoading && (
                      <div style={{ marginTop: '25%', marginLeft: '42%' }}>
                        <CircularProgress style={{ color: '#F2755F' }} />
                      </div>
                    )}
                  </div>
                </div>
                <Button
                  disabled={mobileNumber.length !== 10 || _.isEmpty(name)}
                  variant="contained"
                  color="primary"
                  style={{ fontSize: 12, height: 'fit-content' }}
                  onClick={() => onSubmitLogin()}
                >
                  {'Proceed'}
                </Button>
              </div>
            )}
          </div>
        )}
        <Snackbar
          open={open}
          onClose={handleClose}
          message={errorMessage}
          autoHideDuration={1500}
        />
      </div>
    </div>
  );
};

export default LoginFormWithoutOtp;
