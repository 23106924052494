import React, { FC, useEffect } from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@material-ui/core';
import _ from 'lodash';
import {
  getAccountInfo,
  getAllCountries,
  getAllStatesByCountryCode
} from '../../../services/ecomCart.service';
import AddressListing from './AddressListing';

const defaultAddressForm = {
  firstname: '',
  lastname: '',
  address1: '',
  address2: '',
  city: '',
  phone: '',
  zipcode: '',
  // eslint-disable-next-line @typescript-eslint/camelcase
  state_id: '',
  // eslint-disable-next-line @typescript-eslint/camelcase
  country_id: ''
};
const defaultCountryISO = 'IN';
interface AddressFormProps {
  mobileNumber: string;
  setIsNextEnabled: (d: any) => void;
  setAddressData: (d: any) => void;
  addressData: any;
  setMobileNumber: (d: any) => void;
  email: string;
  setEmail: (d: any) => void;
  setSkipAddressCreation: (d: boolean) => void;
  setShowNextOrFinish: (data: boolean) => void;
}

export const AddressForm: FC<AddressFormProps> = ({
  mobileNumber,
  setIsNextEnabled,
  setAddressData,
  addressData,
  setMobileNumber,
  setEmail,
  email,
  setSkipAddressCreation,
  setShowNextOrFinish
}) => {
  const [allCountries, setAllCountries]: any = React.useState([]);
  const [allStates, setAllStates]: any = React.useState([]);
  const [isAddressFormEnabled, setIsAddressFormEnabled]: any = React.useState(
    false
  );
  useEffect(() => {
    if (_.isEmpty(mobileNumber) || _.isEmpty(email)) {
      getAccountInfo().then(res => {
        setMobileNumber(_.get(res, 'data.attributes.phone_number', ''));
        setEmail(_.get(res, 'data.attributes.email', ''));
      });
    }
    setShowNextOrFinish(true);
  }, [email, mobileNumber, setEmail, setMobileNumber, setShowNextOrFinish]);
  useEffect(() => {
    getAllCountries().then(r => {
      const countries = _.map(r.countries, c => {
        return { label: c.name, value: c.id, iso: c.iso };
      });
      const defaultCountry: any = _.find(countries, c => {
        // eslint-disable-next-line @typescript-eslint/camelcase
        return c.iso === defaultCountryISO;
      });
      setAllCountries(countries);
      setStateValues(defaultCountryISO);
      setAddressData({
        ...defaultAddressForm,
        phone: mobileNumber,
        // eslint-disable-next-line @typescript-eslint/camelcase
        country_id: defaultCountry.value
      });
      setIsNextEnabled(false);
    });
  }, [mobileNumber, setAddressData, setIsNextEnabled]);

  const handleAddressChanges = (field: any, value: any) => {
    const updatedAddress = { ...addressData, [field]: value };
    setAddressData(updatedAddress);
    validateFields(updatedAddress, email);
    if (field === 'country_id') {
      setStateValues(value);
    }
  };

  function setStateValues(countryCode: any) {
    getAllStatesByCountryCode(countryCode).then(r => {
      const states = _.map(r.included, c => {
        return {
          label: c.attributes.name,
          value: c.id,
          abbr: c.attributes.abbr
        };
      });
      setAllStates(states);
    });
  }
  const handleEmailChanges = (emailAddress: any) => {
    validateFields(addressData, emailAddress);
    setEmail(emailAddress);
  };
  const validateEmail = (emailAddress: any) => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(emailAddress);
  };
  const validateFields = (updatedAddress: any, emailAddress: any) => {
    const phoneNumber = _.get(updatedAddress, 'phone');
    setIsNextEnabled(
      !_.includes(_.omit(updatedAddress, 'address2'), '') &&
        phoneNumber.length === 10 &&
        validateEmail(emailAddress)
    );
  };

  const onClickAddAddress = () => {
    setIsAddressFormEnabled(!isAddressFormEnabled);
    setIsNextEnabled(false);
    if (!isAddressFormEnabled) {
      setSkipAddressCreation(false);
    }
  };

  return (
    <div className={'address-form-container'}>
      {isAddressFormEnabled && (
        <div>
          <div className={'address-grid-container'}>
            <div className={'address-item'}>
              <TextField
                error={false}
                fullWidth
                id="outlined"
                label="First Name*"
                helperText=""
                variant="outlined"
                onChange={e =>
                  handleAddressChanges('firstname', e.target.value)
                }
                className={'address-field'}
              />
            </div>
            <div className={'address-item'}>
              <TextField
                error={false}
                fullWidth
                id="outlined"
                label="Last Name*"
                helperText=""
                variant="outlined"
                onChange={e => handleAddressChanges('lastname', e.target.value)}
                className={'address-field'}
              />
            </div>
            <div className={'address-group-one address-item'}>
              <TextField
                error={false}
                fullWidth
                id="outlined"
                label="Address*"
                helperText=""
                variant="outlined"
                onChange={e => handleAddressChanges('address1', e.target.value)}
                className={'address-field'}
              />
            </div>
            <div className={'address-group-two address-item'}>
              <TextField
                error={false}
                fullWidth
                id="outlined"
                label="Address Line 2"
                helperText=""
                variant="outlined"
                onChange={e => handleAddressChanges('address2', e.target.value)}
                className={'address-field'}
              />
            </div>
            <div className={'address-item'}>
              <FormControl variant="outlined" className={'address-field'}>
                <InputLabel id="demo-simple-select-outlined-label">
                  Country*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  defaultValue={'IN'}
                  value={_.get(addressData, 'country_id')}
                  onChange={e =>
                    handleAddressChanges('country_id', e.target.value)
                  }
                  label="Country"
                  style={{ textAlign: 'left' }}
                >
                  {_.map(allCountries, (c, i) => (
                    <MenuItem key={i} value={c.value}>
                      {c.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={'address-item'}>
              <FormControl variant="outlined" className={'address-field'}>
                <InputLabel id="demo-simple-select-outlined-label">
                  State*
                </InputLabel>
                <Select
                  labelId="demo-simple-select-outlined-label"
                  id="demo-simple-select-outlined"
                  value={_.get(addressData, 'state_id')}
                  onChange={e =>
                    handleAddressChanges('state_id', e.target.value)
                  }
                  label="State"
                  style={{ textAlign: 'left' }}
                >
                  {_.map(allStates, (c, i) => (
                    <MenuItem key={i} value={c.value}>
                      {c.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className={'address-item'}>
              <TextField
                error={false}
                fullWidth
                id="outlined"
                label="City*"
                helperText=""
                variant="outlined"
                onChange={e => handleAddressChanges('city', e.target.value)}
                className={'address-field'}
              />
            </div>
            <div className={'address-item'}>
              <TextField
                error={false}
                fullWidth
                id="outlined-number"
                label="Pin code*"
                type="number"
                helperText=""
                variant="outlined"
                onChange={e => handleAddressChanges('zipcode', e.target.value)}
                className={'address-field'}
              />
            </div>
            <div className={'address-item'}>
              <TextField
                error={false}
                fullWidth
                id="outlined-number"
                type="number"
                label="phone*"
                helperText=""
                variant="outlined"
                defaultValue={mobileNumber}
                onChange={e => handleAddressChanges('phone', e.target.value)}
                className={'address-field'}
              />
            </div>
            <div className={'address-item'}>
              <TextField
                error={false}
                fullWidth
                id="outlined-email"
                type="text"
                label="email*"
                helperText=""
                variant="outlined"
                defaultValue={email}
                onChange={e => handleEmailChanges(e.target.value)}
                className={'address-field'}
              />
            </div>
          </div>
          <div>
            <FormControlLabel
              disabled
              control={<Checkbox checked name="checkedE" />}
              className={'shipping-address-checkbox'}
              label="Use Billing Address As Shipping Address"
            />
          </div>
        </div>
      )}
      {!isAddressFormEnabled && (
        <div>
          <AddressListing
            mobileNumber={mobileNumber}
            setIsNextEnabled={setIsNextEnabled}
            setSkipAddressCreation={setSkipAddressCreation}
            setAddressData={setAddressData}
          />
        </div>
      )}
      <div className={'add-new-address-btn'}>
        <p className={'m-0'} onClick={() => onClickAddAddress()}>
          {isAddressFormEnabled ? (
            <u>My Address List</u>
          ) : (
            <u>+ Add New Address</u>
          )}
        </p>
      </div>
    </div>
  );
};

export default AddressForm;
